/**
 * =========================
 * Theme Scripts
 * =========================
 */

jQuery(function($){

	// Starts at slide 0
	$('html').attr('data-current-panel-id', 0);
	setCurrentNavButton(0);

	// Initialize the slider
	var panels = $('#main-panels').slick({
		accessibility: false,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		centerMode: true,
		swipe: false,
		draggable: false,
		centerPadding: '0px',
		speed: 600,
	});

	// Callback when slide changes
	panels.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		$('html').attr('data-current-panel-id', nextSlide);
		setCurrentNavButton(nextSlide);
		parallaxBackground(nextSlide);
	});

	// On pagniation click, change slide
	$('[data-button-goes-to-panel]').click(function(event) {
		event.preventDefault();
		var	panelIndex = $(this).attr('data-button-goes-to-panel');
		panels.slick('slickGoTo', parseInt(panelIndex));
	});

	// Sets a current class to the pagination
	function setCurrentNavButton(currentIndex) {
		$('[data-button-goes-to-panel]').removeClass('button-nav-is-current');
		$('[data-button-goes-to-panel="' + currentIndex + '"]').addClass('button-nav-is-current');
	};

	// Move the background position based on the panel you are viewing
	function parallaxBackground(index) {
		var amountToMove = (index * 100);
		$('#background-wrapper').css({
			'transform': 'translate3d(-' + amountToMove + 'px, 0, 0)',
		});
	}

	// Responsive video
	$(document).ready(function() {
		$('iframe[src*="youtube.com"]').wrap('<div class="responsive-video"></div>');
		$('iframe[src*="youtube-nocookie.com"]').wrap('<div class="responsive-video"></div>');
		$('iframe[src*="player.vimeo.com"]').wrap('<div class="responsive-video"></div>');
	});
});
